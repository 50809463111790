.formatted-text {
    white-space: pre-line;
}

.chatBubble {
    padding: 10px;
    margin: 10px 0;
    border-radius: 10px;
    background-color: white;
    max-width: 94%;
    border: 1px solid lightgrey;
    font-family: 'Roboto', sans-serif;
}
  
.queryStyle {
    align-self: flex-start;
    margin-bottom: 20px;
}

.responseStyle {
    align-self: flex-end;
    margin-bottom: 20px;
}

.separatorStyle {
    height: 20px;
    background-color: #F5F5F5;
    margin: 20px 0; /* Adjust as needed */
}

.containerPaddingStyle {
    padding: 20px;
}

.queryAuthorStyle {
    color: #6495ED; /* Hexadecimal value */
    font-weight: 600; /* Semi-bold */
    font-size: 0.9em; /* Slightly smaller text */
    margin-bottom: 10px;
    margin-top: 10px;
}

.responseAuthorStyle {
    color: #DDA0DD;
    font-weight: 600; /* Semi-bold */
    font-size: 0.9em; /* Slightly smaller text */
}

.chatBoxContainerStyle {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Align children to start and end of container */
    min-height: 100vh; /* Make container full height of viewport */
    position: relative; /* For the sticky positioning of chat input */
}

.chatHistoryStyle {
    overflow-y: auto;
    padding: 20px;
    margin-bottom: auto; /* Pushes everything below it down */
}

.inputAndIconContainer {
    display: flex; /* Align children in a row */
    align-items: center; /* Vertically center the children */
    justify-content: flex-start; /* Align children to the start of the container */
    width: 100%; /* Take full width to ensure it stretches across the bottom */
    padding: 10px 0; /* Add some vertical padding for spacing */
  }
  
  .settingsIconContainer {
    position: relative;
    margin-right: 10px; /* Add some space between the icon and the input bar */
    cursor: pointer;
  }

/* Or any color you prefer 
    position: sticky; 
    display: flex; 
    align-items: center; 
    justify-content: space-between;
    
    
    .settingsIconContainer {
    margin-right: 8px;
    margin-bottom: 8px;    
  }
  
  */

   /* Other styling as needed 
    position: relative;
    cursor: pointer;
    
    */

.chatInputContainerStyle {
    bottom: 0; /* Stick to the bottom */
    padding: 60px;
    background-color: white; 


}

 
  .menuPopup {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    z-index: 1000;
    bottom: 100%; 
    right: 100; 
  }
  
  .menuPopup ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .menuPopup ul li {
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .menuPopup ul li:hover {
    background-color: #f0f0f0;
  }
  
  .modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modalContent {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
  .conversationItem {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .conversationItem:hover {
    background-color: #f0f0f0;
  }
  