.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.policy {
  padding-left: 40px;
  padding-right: 40px;
}

.navbar-header {
  display: flex;
  flex-direction: row; /* Ensure this is row for a horizontal layout */
  justify-content: space-between; /* Adjust as needed for spacing */
  align-items: center; /* Aligns items vertically in the center */
  width: 100%; /* To ensure it takes the full width of the page */
  /* Add any other styles as necessary */
}

.frame-5 {
  display: flex;
  flex-direction: row; /* Aligns children horizontally */
  justify-content: start; /* Aligns children to the start of the container */
  align-items: center; /* Aligns children vertically in the center */
}

.app-container {
  display: flex;
  flex-direction: row; /* Align children side by side */
  /* If you want the NavBarSide to have a fixed width */
  /* width: 100%; Ensure the container takes full width if necessary */
}

.navbar-side {
  flex: 0 0 auto; /* don't grow, don't shrink, initial basis auto */
  /* width: 200px; Optionally set a fixed width */
}

.main-content {
  flex: 1; /* Grow to fill the remaining space */
  /* width: calc(100% - 200px); Optionally set width to fill the remaining space */
}

.loadMoreButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px 0;
}

.button-style {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: bold;
  flex-grow: 1; /* Allows buttons to grow and fill the available space */
  text-align: center;
}

.button-style.active {
  background-color: #007bff; 
  /* Other styles for active state */
}

.response {
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f0f0; /* Light gray background */
  border: 1px solid #d0d0d0; /* Gray border */
}

.error {
  margin-top: 20px;
  color: red; /* Red text for errors */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Media query for small screens */
@media (max-width: 600px) {
  .button-style {
    padding: 8px 10px; /* Slightly smaller padding */
    font-size: 0.8em; /* Smaller font size */
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
